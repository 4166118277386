require('./bootstrap');

window.checkDelete = function () {
	if (window.confirm('削除してよろしいですか？')) {
		return true;
	}
	else {
		return false;
	}
};

window.checkAuthorize = function () {
	if (window.confirm('ユーザを承認しますか？一度承認したら取り消せません。')) {
		return true;
	}
	else {
		return false;
	}
};